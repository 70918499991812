// TODO: Figure out why the rides and ride_photos props aren't a consistent data type

/* eslint-disable no-alert */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Popup from 'reactjs-popup';
import { getVehicleRides } from '../../state/vehicle_rides';
import { getRidePhoto, sendPhotoReview } from '../../state/ride_photos';
import { clearUser, getUser, getRidesForUser, patchUser } from '../../state/user';
import {
  endRide,
  getRefundForRide,
  getRideLocations,
  getRidesInZone,
  getRideById,
} from '../../state/rides';
import { setSelectedZone } from '../../state/selected_zone';
import { getRestrictedAreas } from '../../state/restricted_areas';
import AuthenticatedPage from '../../Components/AuthenticatedPage';
import { connect } from 'react-redux';
import { DropdownList, DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import simpleNumberLocalizer from 'react-widgets-simple-number';
import Loader from '../../Components/Loader';
import { bindActionCreators } from 'redux';
import { RIDE_MAP, RIDE_PHOTO } from '../../util/constants';
import { getTimeInterval, getActiveZones } from '../../util/helper';
import Map from '../../Components/ZoneMap';
import RidePhotosTable from '../../Components/RidePhotosTable';
import RidePhotoReview from '../../Components/RidePhotoReview';

import closeButtonImage from '../../images/close.png';

import './index.scss';

const { GATSBY_REACT_APP_API_HOST: API_HOST } = process.env;
const API_HOST_V2 = `${API_HOST}/api/v2`;
const moment = require('moment-timezone');

simpleNumberLocalizer();
moment.locale('en');
momentLocalizer();

class RidePhotos extends Component {
  constructor(props) {
    super(props);
    let user_id;
    if (this.props.location && this.props.location.state && this.props.location.state.user_id) {
      user_id = this.props.location.state.user_id;
    }
    const { user } = this.props;
    const selectedZone = this.props.selected_zone
      ? this.props.zones.find((zone) => zone.id === this.props.selected_zone)
      : undefined;
    this.state = {
      user_id,
      user,
      feedback: '',
      disabled: false,
      ride: user && user.ride,
      loaded: true,
      submitBtnVal: 'Submit Ride Id',
      open: false,
      vehicleRidesTableData: [],
      propType: '',
      mapPlaces: [],
      mapCenter: {},
      mapZoneBoundary: [],
      ride_photo: '',
      showZoneSelect: true,
      showCancelButton: false,
      showRidesTable: false,
      showDateSelector: false,
      showRideIdInput: true,
      ride_id: '',
      ride_date: new Date(),
      ridesTableRides: [],
      photo_ride_id: '',
      photo_vehicle_name: '',
      photo_user_phone: '',
      photo_user_id: '',
      photo_id: '',
      activeZones: getActiveZones(this.props.zones, this.props.admin.user_default_zones),
      selectedZone,
    };
    selectedZone && this.handleZoneChange(selectedZone, 'zone_state');
  }

  closePopup = () => this.setState({ open: false });

  handleRideIdChange = (event) => this.setState({ ride_id: event.target.value });

  handleZoneChange = async (val, callType) => {
    let selectedZone = {};
    if (callType === 'zone_state') {
      //This is being called because a zone is saved as a state var, not because of a change in the dropdown select
      selectedZone = this.state.activeZones.find((zone) => zone.id === this.props.selected_zone);
    } else {
      if (typeof val === 'object') {
        selectedZone = val;
      } else {
        const zone_id = this.props.zones.filter((zone) => zone.id === val);
        selectedZone = zone_id[0];
      }
      await this.props.actions.setSelectedZone(selectedZone.id);
    }
    this.setState({
      selectedZone: selectedZone,
      showDateSelector: true,
      showCancelButton: true,
      showRidesTable: false,
      ridesTableRides: [],
    });
  };

  onSubmitRideId = async (event) => {
    event.preventDefault();
    const ride_id = this.state.ride_id;
    try {
      this.setState({ loaded: false });
      const params = {
        ride_id: ride_id,
      };
      this.props.actions.getRideById(params).then(() => {
        this.setState({
          ridesTableRides: this.props.rides.ride,
          feedback: '',
          showRidesTable: true,
          showZoneSelect: false,
          showCancelButton: true,
          showRideIdInput: false,
          loaded: true,
        });
      });
    } catch (error) {
      this.setState({
        feedback: 'Could not find Ride',
        disabled: false,
        loaded: true,
      });
    }
  };

  getRidesByDay = async () => {
    this.setState({
      loaded: false,
      ridesTableRides: [],
    });
    const month = this.state.ride_date.getUTCMonth() + 1;
    const day = this.state.ride_date.getDate();
    const year = this.state.ride_date.getUTCFullYear();
    const end_day = day + 1;
    const selected_date = year + '-' + month + '-' + day;
    const end_date = year + '-' + month + '-' + end_day;
    const start_time = '00:00:00';
    const start = moment(selected_date + ' ' + start_time)
      .tz('America/Los_Angeles')
      .format('MMM DD YYYY HH:mm:ss');
    const end = moment(end_date + ' ' + start_time)
      .tz('America/Los_Angeles')
      .format('MMM DD YYYY HH:mm:ss');
    const params = {
      start: start,
      end: end,
      includeRideDetails: true,
      includeEndRidePhoto: true,
    };
    this.props.actions.getRidesInZone(this.state.selectedZone.id, params).then(() => {
      if (this.props.rides.routes.length < 1) {
        alert('There are no rides in the selected zone and timeframe');
        this.setState(
          {
            loaded: false,
          },
          () => alert('There are no rides in the selected zone and timeframe')
        );
      } else {
        this.setState({
          loaded: true,
          showRideIdInput: false,
          showDateSelector: false,
          showRidesTable: true,
          showCancelButton: true,
          ridesTableRides: this.props.rides.routes,
        });
      }
    });
  };

  startOver = () => {
    window.location.reload();
  };

  postReview = (rideId, photoId, review) => {
    const params = {
      review_type: review,
    };
    this.props.actions.sendPhotoReview(rideId, photoId, params).then(() => {
      this.closePopup();
      alert('Photo successfully reviewed');
      this.getRidesByDay();
    });
  };

  handleChangeZones = () => {
    this.setState({
      submitBtnVal: 'Update User',
    });
  };

  addMarker = (formattedPlace) => {
    for (const value of formattedPlace) {
      const newPlace = {
        id: value.id,
        lat: value.lat,
        lng: value.lng,
        time: value.time,
        backgroundColor: value.backgroundColor,
        zindex: value.zindex,
      };
      this.setState({
        mapPlaces: [...this.state.mapPlaces, newPlace],
      });
    }
  };

  checkDuration = (start, end) => {
    const interval = getTimeInterval(start, end);
    if (interval < 1) {
      return true;
    } else {
      return false;
    }
  };

  openMapPopup = async (start, end_location, end_time, zone_id, ride_id) => {
    this.setState({
      popupType: RIDE_MAP,
      loaded: false,
    });
    let map_zone = 0;
    let zone_boundary_points = [];
    this.setState({
      loaded: false,
      mapPlaces: [],
    });
    let vehicleLocationCount = 0;
    let formattedPlaces = [];
    this.props.actions
      .getRideLocations(ride_id)
      .then(() => {
        let timeLbl = '';
        let lat = '';
        let lng = '';
        let zIndex = 0;
        let backgroundColor = '';
        let locations = Object.entries(this.props.rides.locations);
        vehicleLocationCount = locations.length - 1;
        formattedPlaces = locations.map((place, i) => {
          if (i === 0) {
            //start location, properties saved and pushed on to end of map places array after array of places is built so it shows on top
            this.setState({ mapCenter: { lat: place[1].latitude, lng: place[1].longitude } });
            timeLbl = 'Start - O min';
            lat = place[1].latitude;
            lng = place[1].longitude;
            backgroundColor = 'Green';
            // set zIndex of start marker to highest so it displays
            zIndex = vehicleLocationCount + 2;
            map_zone = zone_id;
            if (this.props.zones.length > 0) {
              this.props.zones.forEach((zone) => {
                if (zone.id === map_zone) {
                  zone.geofence.forEach((points) => {
                    const coords = {
                      lat: points[0],
                      lng: points[1],
                    };
                    zone_boundary_points.push(coords);
                  });
                }
              });
            }
            this.setState({
              mapZoneBoundary: zone_boundary_points,
            });
          } else if (i > 0 && i <= vehicleLocationCount - 1) {
            // between start and end location
            const start_date = new Date(start);
            timeLbl = getTimeInterval(start_date, place[1].created_date);
            timeLbl = timeLbl + ' min';
            lat = place[1].latitude;
            lng = place[1].longitude;
            backgroundColor = 'Cornsilk';
            zIndex = i;
          } else if (i === vehicleLocationCount) {
            //last recorded ride location
            const start_date = new Date(start);
            // use the end_location from the rides table for the last location since
            //this may not have been recorded in vehicle_location_logs
            const end_date = new Date(end_time);
            timeLbl = getTimeInterval(start_date, end_date);
            timeLbl = 'End - ' + timeLbl + ' min';
            lat = end_location.latitude;
            lng = end_location.longitude;
            backgroundColor = 'Red';
            zIndex = vehicleLocationCount + 1;
          }
          return {
            id: place[1].id,
            lat: lat,
            lng: lng,
            time: timeLbl,
            backgroundColor: backgroundColor,
            zindex: zIndex,
          };
        });
        this.setState({
          mapPlaces: formattedPlaces,
        });
      })
      .then(() => this.props.actions.getRestrictedAreas(zone_id))
      .then(() => {
        if (vehicleLocationCount > 0) {
          this.setState(
            { popupType: RIDE_MAP },
            this.setState({
              open: true,
              loaded: true,
            })
          );
        } else {
          alert('Error: There was an error loading the Ride Map for this ride.');
          this.setState({
            loaded: true,
          });
        }
      });
  };

  openRidePhotoPopup = (ride) => {
    this.props.actions.getRidePhoto(ride.id, ride.end_ride_photo_id).then(() => {
      if (this.props.ride_photos === 'Not Found') {
        alert('No image for this ride');
      } else {
        let photo_review = '';
        if (ride.end_ride_photo_review !== null) {
          photo_review = ride.end_ride_photo_review.review;
        } else {
          photo_review = null;
        }
        this.setState(
          {
            popupType: RIDE_PHOTO,
            photo_ride_id: ride.id,
            photo_user_id: ride.user.id,
            photo_vehicle_name: ride.vehicle.name,
            photo_user_phone: ride.user.phone,
            photo_id: ride.end_ride_photo_id,
            photo_review: photo_review,
          },
          this.setState({
            open: true,
            loaded: true,
          })
        );
      }
    });
  };

  openPopup = (val, type) => {
    switch (type) {
      case RIDE_MAP:
        this.openMapPopup(
          val.start_time,
          val.end_location,
          val.end_time,
          val.start_zone_id,
          val.id
        );
        break;
      case RIDE_PHOTO:
        this.openRidePhotoPopup(val);
        break;
      default:
        break;
    }
  };

  loadPopup = () => {
    const type = this.state.popupType;
    switch (type) {
      case RIDE_PHOTO:
        return (
          <Fragment>
            <div className="closeModalBtn" onClick={this.closeModal}>
              <img
                src={closeButtonImage}
                alt="Close Modal Button"
                onClick={this.closePopup}
                className=""
                style={{
                  paddingTop: '3px',
                  alignSelf: 'center',
                }}
              />
            </div>
            <RidePhotoReview
              rideId={this.state.photo_ride_id}
              userId={this.state.photo_user_id}
              userPhone={this.state.photo_user_phone}
              vehicleName={this.state.photo_vehicle_name}
              review={this.state.photo_review}
              postReview={this.postReview}
              photoId={this.state.photo_id}
              photoUrl={`${API_HOST_V2}/rides/${this.state.photo_ride_id}/photos/${this.state.photo_id}`}
              photoReview={this.state.photo_review}
            />
          </Fragment>
        );
      case RIDE_MAP:
        return (
          <Map
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAJIYWGel69VKdzFDmRV3fYZay_pO36GOk&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '600px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            center={this.state.mapCenter}
            zoom={16}
            places={this.state.mapPlaces}
            routePlaces={this.state.mapPlaces}
            restrictedAreas={this.props.restrictedAreas}
            showPolygons={true}
            mapZoneBoundary={this.state.mapZoneBoundary}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      showZoneSelect,
      selectedZone,
      showRidesTable,
      showDateSelector,
      showRideIdInput,
      showCancelButton,
    } = this.state;
    const popup = this.loadPopup;
    return (
      <AuthenticatedPage className="edit_vehicle_container" onClick={this.closePopup}>
        <Popup
          open={this.state.open}
          closeOnDocumentClick
          onClose={this.closePopup}
          overlayStyle={{ backgroundColor: 'light-grey' }}
          contentStyle={{ maxHeight: '100vh', overflowY: 'auto' }}>
          {popup}
        </Popup>
        <Loader loaded={this.state.loaded} />
        <div className="page-title">Get Ride Photos</div>
        {showZoneSelect && (
          <Fragment>
            <h3>Select Zone</h3>
            <DropdownList
              className="vehicles_container__select"
              data={this.state.activeZones}
              value={selectedZone}
              valueField="id"
              textField="name"
              onChange={this.handleZoneChange}
              placeholder="Select a Zone"
            />
          </Fragment>
        )}
        {showRidesTable && (
          <RidePhotosTable
            onClick={this.openPopup}
            rides={this.state.ridesTableRides}
            loadRidePhoto={this.loadRidePhoto}
          />
        )}
        {showDateSelector && (
          <Fragment>
            <h3>Select day to view ride photos in {this.state.selectedZone.name}</h3>
            <div className="dateSelectorContainer">
              <DateTimePicker
                time={false}
                editFormat={'YYYY-MM-DD'}
                format={'YYYY-MM-DD'}
                className="add_pricing_plan_form__data_input"
                defaultValue={new Date()}
                onChange={(value) => this.setState({ ride_date: value })}
              />
            </div>
            <div className="getRideButton">
              <input type="button" value="Get Rides" onClick={this.getRidesByDay} />
            </div>
          </Fragment>
        )}
        {showCancelButton && <input type="button" value="Start Over" onClick={this.startOver} />}
        {showRideIdInput && (
          <Fragment>
            <h3>OR</h3>
            <form onSubmit={this.onSubmitRideId}>
              <h3>Enter Ride Id:</h3>
              <input type="text" value={this.state.ride_id} onChange={this.handleRideIdChange} />
              <input type="submit" value={this.state.submitBtnVal} />
            </form>
          </Fragment>
        )}
      </AuthenticatedPage>
    );
  }
}

// RidePhotos.propTypes = {
//   location: PropTypes.object,
//   user: PropTypes.object,
//   zones: PropTypes.array,
//   admin: PropTypes.object,
//   actions: PropTypes.object,
//   selected_zone: PropTypes.number,
//   rides: PropTypes.object,
//   restrictedAreas: PropTypes.array,
//   ride_photos: PropTypes.array,
// };

const mapStateToProps = (state) => {
  const {
    admin,
    user,
    rides,
    ride_photos,
    vehicle_rides,
    zones: { zones },
    restricted_areas: { restrictedAreas },
    selected_zone,
  } = state;
  const restrictedAccess = admin && admin.restricted_access;
  return {
    admin,
    restrictedAccess,
    user,
    rides,
    ride_photos,
    vehicle_rides,
    zones,
    restrictedAreas,
    selected_zone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        getActiveZones,
        getVehicleRides,
        getRefundForRide,
        getUser,
        getRidesForUser,
        clearUser,
        patchUser,
        endRide,
        getRideLocations,
        getRestrictedAreas,
        getRidePhoto,
        getRidesInZone,
        getRideById,
        sendPhotoReview,
        setSelectedZone,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RidePhotos);
