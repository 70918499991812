import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { RIDE_PHOTO, RIDE_MAP } from '../../util/constants';
import { displayDuration } from '../../util/helper';
import map_btn from '../../images/map.png';
import ridePhotoImage from '../../images/ride_photo.png';

const moment = require('moment-timezone');

export default class RidePhotosTable extends Component {
  sendPhotoReview = () => {
    this.props.sendPhotoReview();
  };

  render() {
    const { rides, onClick } = this.props;
    return (
      <Fragment>
        <h2>Rides Table</h2>
        <ReactTable
          ref={(r) => {
            this.selectTable = r;
          }}
          filterable
          onFilteredChange={this.onFilteredChange}
          className="-striped -highlight users_table"
          data={rides}
          showPaginationBottom={false}
          pageSize={rides.length}
          columns={[
            {
              Header: 'Start (Pacific)',
              accessor: 'start_time',
              Cell: (props) => (
                <Fragment>
                  {props.value &&
                    moment(props.value).tz('America/Los_Angeles').format('MMM DD YYYY, h:mm a')}
                </Fragment>
              ),
              maxWidth: 175,
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return moment(a).isBefore(b) ? 1 : -1;
              },
            },
            {
              Header: 'Ride Id',
              accessor: 'id',
              Cell: (props) => <Fragment>{props.value}</Fragment>,
              maxWidth: 150,
            },
            {
              Header: 'Duration',
              accessor: 'duration',
              Cell: (props) => <Fragment>{displayDuration(props.value)}</Fragment>,
              maxWidth: 120,
              sortMethod: (a, b) => {
                if (a === b) {
                  return 0;
                }
                return a < b ? 1 : -1;
              },
            },
            {
              Header: 'Ride End Reason',
              accessor: 'ride_end_reason',
              Cell: (props) => <Fragment>{props.value}</Fragment>,
              maxWidth: 150,
            },
            {
              Header: 'Map',
              accessor: 'map',
              Cell: (props) => (
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={map_btn}
                    alt="Close Modal Button"
                    onClick={(event) => {
                      onClick(props.original, RIDE_MAP);
                      event.stopPropagation();
                    }}
                    className=""
                    style={{
                      paddingTop: '3px',
                      alignSelf: 'center',
                      height: '25px',
                      width: '25px',
                    }}
                  />
                </div>
              ),
            },
            {
              Header: 'End Ride Photo',
              accessor: 'end_ride_photo_id',
              width: 100,
              Cell: (props) => (
                <div style={{ textAlign: 'center' }}>
                  {props.value !== null && (
                    <img
                      src={ridePhotoImage}
                      alt="Ride Photo Button"
                      onClick={(event) => {
                        onClick(props.original, RIDE_PHOTO);
                        event.stopPropagation();
                      }}
                      className=""
                      style={{
                        paddingTop: '3px',
                        alignSelf: 'center',
                      }}
                    />
                  )}
                </div>
              ),
            },
            {
              Header: 'Photo Review',
              accessor: 'end_ride_photo_review.review',
              Cell: (props) => <Fragment>{props.value}</Fragment>,
              maxWidth: 150,
            },
          ]}
          defaultSorted={[
            {
              id: 'start_time',
              desc: false,
            },
          ]}
        />
      </Fragment>
    );
  }
}

RidePhotosTable.propTypes = {
  rides: PropTypes.array,
  onClick: PropTypes.func,
  sendPhotoReview: PropTypes.func,
};
